<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/banner_img5.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`${apiName}business?t=${index}`">{{item.name}}</a>
                <span></span>
              </div>
              <!-- <div class="listSlidListInner">
                <a href="/list?t=1">项目动态</a>
                <span></span>
              </div> -->
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd listHdPage clearfloat">
              <div class="fl listHdName">核心业务</div>
              <div class="fr listHdGrop"><a :href="`${apiName}`">首页</a> · <a :href="`${apiName}business`">核心业务</a> · <span class="txt">{{hdName}}</span> </div>
            </div>
            <div
              class="listContainer"
              v-loading="loading"
              element-loading-text="Loading"
              element-loading-spinner="el-icon-loading"
              v-html="pageCont">
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'list',
  data(){
    return {
      loading: false,
      listId: null,
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '核心业务',
        sub: 'CORE BUSINESS',
      },
      slideList:[
        {
          title: '智慧交通',
          id: '11111'
        },
        {
          title: '智慧旅游',
          id: '11112'
        },
        {
          title: '智能网联',
          id: '11113'
        },
        {
          title: '智慧金融',
          id: '11114'
        },
        {
          title: '智慧城市',
          id: '11115'
        },
        {
          title: '智慧物联',
          id: '11116'
        },
      ],
      pageSize: 10,
      pageNum: 1,
      total: 20,
      listData: [
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
      ],
      page: [
        '<p>智慧交通</p><p>智慧交通</p><p>智慧交通</p>',
        '<p>智慧旅游</p><p>智慧旅游</p><p>智慧旅游</p> <p>智慧旅游</p><p>智慧旅游</p>',
        '<p>智能网联</p><p>智能网联</p><p>智能网联</p> <p>智能网联</p><p>智能网联</p>',
        '<p>智慧金融</p><p>智慧金融</p><p>智慧金融</p> <p>智慧金融</p><p>智慧金融</p>',
        '<p>智慧城市</p><p>智慧城市</p><p>智慧城市</p> <p>智慧城市</p>',
        '<p>智慧物联</p><p>智慧物联</p><p>智慧物联</p>',
      ]
    }
  },
  mounted(){
    var _this = this
    if(_this.$route.query.t){
      _this.active = _this.$route.query.t
      // _this.navNameShow = true
      // _this.name1 = _this.$route.query.navName;
      // _this.navNameUrl = _this.navNameList[_this.$route.query.navName]
    }else{
      _this.active = 0
    }

    _this.getSideNav()
  },
  methods: {
    currentChange(e){
      console.log('e :>> ', e);
    },
    // 获取二级栏目
    async getSideNav() {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: "118"});
      _this.slideList = data.data
      _this.hdTitle = _this.slideList[_this.active].name
      _this.hdName = _this.slideList[_this.active].name
      _this.listId = _this.slideList[_this.active].id
      _this.getList(_this.slideList[_this.active].id)
    },
    // 查列表
    async getList(id) {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: id, page: 1, pageSize: 10});
      _this.getDetail(data.data.list[0].id)
    },
    // 查详情
    async getDetail(id) {
      var _this = this;

      _this.loading = true
      var { data } = await _this.$http.post( _this.apiUrl + "/news/getNewsDetail", { id: id });
      _this.pageCont = data.data.content;
      _this.loading = false
    },
  }
}
</script>
<style scoped>
.day {
  display: inline-block;
}
</style>